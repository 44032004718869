@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";

// hero banner
.store{
    .sub-main{
        .slide-wrap {
            &.hero-banner {
                .slide-content {
                    .slide-track  {
                        height:640px;
                        @include mobile{
                            height:370px;
                        }
                        .slide-conts {
                            position:relative;
                            height:640px;
                            @include mobile{
                                height:370px;
                            }
                            .slide-img {
                                overflow:hidden;
                                position: relative;
                                width:100%;
                                height:100%;
                                img, video {
                                    position:absolute;
                                    top:0;
                                    left:50%;
                                    transform:translateX(-50%);
                                    width: auto;
                                    height: auto;
                                    min-width: 100%;
                                    min-height: 100%;
                                    max-width:none;
                                    @media screen and (min-width:1920px) {
                                        width:100%;
                                        height:auto;
                                        top:50%;
                                        left:0;
                                        transform:translateY(-50%);
                                    }
                                    @include mobile {
                                        width:100%;
                                        height:auto;
                                        top:50%;
                                        left:0;
                                        transform:translateY(-50%);
                                    }
                                }
                                video{
                                    @include mobile {
                                        width:auto;
                                        height:100%;
                                        top:0;
                                        left:50%;
                                        transform:translate(-50%, 0);
                                    }
                                }
                            }
                            .bg {
                                background-size: cover;
                                &.pc{
                                    display:inline-block;
                                }
                                &.mobile{
                                    display:none;
                                }
                                @include mobile {
                                    height: 100%;
                                    padding-bottom: 0;
                                    &.pc{
                                        display:none;
                                    }
                                    &.mobile{
                                        display:inline-block;
                                    }
                                }
                            }
                            .slide-info-area {
                                top:100px;
                                left:0;
                                bottom:120px;
                                max-width:1614px;
                                margin:0 auto;
                                padding: 0 40px;
                                transform:translateX(0);
                                @include mobile {
                                    top:32px;
                                    right:20px;
                                    bottom:102px;
                                    left:20px;
                                    padding: 0;
                                }
                                .boxs {
                                    display:inline-block;
                                    &.fc-black {
                                        color:#000;
                                    }
                                    &.fc-white {
                                        color:#fff;
                                    }
                                    .txt {
                                        color:inherit;
                                        font-size:52px;
                                        font-weight:700;
                                        line-height:62px;
                                        @include mobile{
                                            position:relative;
                                            font-size:27px;
                                            line-height:34px;
                                        }
                                    }
                                    .small {
                                        margin-top:16px;
                                        font-size:24px;
                                        line-height:32px;
                                        @include mobile{
                                            margin-top:7px;
                                            font-size:16px;
                                            line-height:22px;
                                        }
                                    }
                                }
                            }
                            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                .slide-info-area {
                                    width:100%;
                                }
                            }
                            .link {
                                position:absolute;
                                right:0;
                                bottom:0;
                                padding-right:60px;
                                color:#fff;
                                font-size:52px;
                                font-weight:700;
                                line-height:70px;
                                text-align:right;
                                @include mobile{
                                    padding-right:22px;
                                    font-size:16px;
                                    line-height:24px;
                                }
                                &:after {
                                    content:' ';
                                    position:absolute;
                                    right:0;
                                    bottom:8px;
                                    width:56px;
                                    height:56px;
                                    background:url('/lg5-common/images/PRS/btn-arrow-right-56.svg') no-repeat 0 0;
                                    @include mobile{
                                        bottom:2px;
                                        width:20px;
                                        height:20px;
                                        background-size:20px;
                                    }
                                }
                                &.fc-black {
                                    color:#000;
                                    &:after {
                                        background-image:url('/lg5-common/images/PRS/btn-arrow-right-56-black.svg');
                                    }
                                }
                                &.fc-white {
                                    color:#fff;
                                }
                            }
                        }
                    }
                }
                .custom-indi-wrap {
                    display:none;
                    bottom:24px;
                    left:0;
                    right:0;
                    max-width:1380px;
                    margin:0 auto;
                    @include mobile{
                        bottom: 20px;
                        right: 16px;
                        left: 16px;
                        max-width: none;
                    }
                    .custom-indis {
                        max-width: (1380px + 30px);
                        @include mobile{
                            margin: 0 -5px;
                        }
                        .indis {
                            width: 100%;
                            table-layout: fixed;
                            li {
                                width: auto;
                                padding: 0 15px;
                                @include mobile{
                                    padding: 0 5px;
                                }
                                &.on {
                                    width: auto;
                                }
                            }
                        }
                    }
                    .slide-controls {
                        position:relative;
                        top:auto;
                        left:auto;
                        right:54px;
                        transform:translateX(0);
                        max-width:1380px;
                        margin:0 auto;
                        @media screen and (max-width: (1460px + 30px)) {
                            right:69px;
                        }
                        @include mobile{
                            right:34px;
                        }
                        .controls{
                            position:absolute;
                            bottom:0;
                            right:0;
                            width:150px;
                            height:46px;
                            border-radius:26px;
                            background:rgba(0, 0, 0, 0.35);
                            @include mobile{
                                width:84px;
                                height:26px;
                                border-radius:13px;
                            }
                        }
                        .btn-arrow {
                            position:absolute;
                            top:9px;
                            padding:0;
                            width:24px;
                            height:24px;
                            @include mobile{
                                top:3px;
                                width:16px;
                                height:16px;
                            }
                            &:before{
                                content:'';
                                display:block;
                                width:24px;
                                height:24px;
                                box-shadow:none;
                                @include mobile{
                                    width:16px;
                                    height:16px;
                                }
                            }
                            &.ico-prev {
                                left:16px;
                                @include mobile{
                                    left:6px;
                                }
                                &:before{
                                    background-image: url(/lg5-common/images/icons/btn-arr-24x24-lt.svg);
                                    @include mobile{
                                        background-size:16px 16px;
                                    }
                                }
                            }
                            &.ico-next {
                                right:16px;
                                @include mobile{
                                    right:6px;
                                }
                                &:before{
                                    background-image: url(/lg5-common/images/icons/btn-arr-24x24-rt.svg);
                                    @include mobile{
                                        background-size:16px 16px;
                                    }
                                }
                            }
                        }
                        .slide-page{
                            padding:0 4px;
                            color:#fff;
                            font-size:16px;
                            line-height:42px;
                            @include mobile{
                                font-size:12px;
                                line-height:23px;
                            }
                            .count{
                                color:#d6d6d6;
                                &:before{
                                    content:'/';
                                    margin:0 8px;
                                    color:#fff;
                                }
                            }
                        }
                    }
                    .indi-control {
                        display: block !important;
                        position: absolute;
                        bottom:0;
                        right:0;
                        margin-right:0;
                        width:46px;
                        height:46px;
                        @include mobile{
                            width:26px;
                            height:26px;
                        }
                        &:before{
                            width:46px;
                            height:46px;
                            @include mobile{
                                width:26px;
                                height:26px;
                            }
                        }
                        &.play{
                            &:before{
                                background: url(/lg5-common/images/icons/btn-carousel-46-play.svg) no-repeat 0 0;
                                @include mobile{
                                    background-size:26px 26px;
                                }
                            }
                        }
                        &.stop{
                            &:before{
                                background: url(/lg5-common/images/icons/btn-carousel-46-pause.svg) no-repeat 0 0;
                                @include mobile{
                                    background-size:26px 26px;
                                }
                            }
                        }
                        @media screen and (max-width: (1460px + 30px)) {
                            right: 15px;
                            margin-right: 0;
                            @include mobile {
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}



// 서브메인
.sub-main {
	padding-bottom:120px;
	@include mobile {
		padding-bottom:70px;
	}
	.btn {
		&.border {
			&:hover, &:focus, &:active {
				border-color:inherit;
				background-image:inherit;
			}
		}
	}
	/* BTOCSITE-6882 신규 WSG 적용 - 스토어 */
	.module-box {
		position:relative;
		max-width:1460px;
		margin:100px auto 0;
		padding:0 40px;
		@include mobile {
			margin-top:58px;
			padding:0;
		}
		.tit-wrap {
			margin-bottom:32px;
			text-align:center;
			@include mobile{
				margin-bottom:19px;
			}
			.tit {
				font-size:32px;
				font-weight:700;
				line-height:40px;
				@include mobile{
					font-size:20px;
					line-height:26px;
				}
				em {
					font-style:normal;
					color:#da0f47;
				}
				.name {
					color: $color-gray-1;
				}
			}
			.desc {
				display:none;
				margin-top:12px;
				font-size:18px;
				line-height:28px;
				@include mobile{
					margin-top:6px;
					font-size:14px;
					line-height:22px;
				}
			}
		}
		.btn{
			@include mobile{
				padding:7px 14px !important;
				font-size:14px;
			}
		}
		.tabs-wrap.border-type{
			@include mobile{
				margin-top:32px;
				&:before {
					display:none;
				}
			}
		}
		
		.slide-wrap {
			@include mobile {
				padding-bottom:24px;
			}
			.indi-wrap{
				bottom:0;
				li{
					margin-left:16px;
					&:first-child{
						margin-left:0;
					}
					.btn-indi{
						width:10px;
						height:10px;
						&:before{
							width:100%;
							height:100%;
							background:#e4e4e4;
						}
					}
					&.on{
						.btn-indi{
							width:10px;
							height:10px;
							&:before{
								width:100%;
								height:100%;
								background:#000;
							}
						}
					}
				}
			}
		}
		
		//카테고리
		&.cnt01 {
			position: relative;
			margin-top:48px;
			padding: 0;
			&:before,
			&:after {
				position: absolute;
				top: 0;
				z-index: 1;
				width: 40px;
				height: 43px;
				content: '';
			}
			&:before {
				left: 0;
				background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
			}
			&:after {
				right: 0;
				background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
			}
			@include pc {
				.tabs-wrap {
					max-width: (1380px + 80px);
					margin: 0 auto;
					padding: 0 40px;
					text-align: left;
					@media screen and (min-width:(1380px + 80px)) {
						&:before {
							right: 40px;
							left: 40px;
						}
					}
				}
				.ui_category_tab_contents {
					padding: 0 40px;
				}
			}
			@include mobile {
				margin-top:0;
				&:before,
				&:after {
					width: 16px;
					height: 71px;
				}
			}

			//BTOCSITE-7335
			.ui_category_tab_contents {
				@include mobile{
					z-index: 10;
					position: relative;
				}
				.tabs-contents {
					display:none;
				}
			}
		}
		.tabs-contents {
			@include mobile{
				background-color:#f4f4f4;
				.slide-wrap.slide-solo {
					padding-bottom: 0;
				}
			}
			.category-list {
				ul {
					margin-top: 20px;
					font-size:0;
					@include mobile{
						display: flex;
						flex-wrap: wrap;
						height:100%;
						margin-top:0;
						padding: 25px 0;
						overflow:hidden;
					}
					li {
						display:inline-block;
						width:25%;
						margin-top:28px;
						@media screen and (min-width:768px) and (max-width:1200px) {
							width:33.33%;
						}
						@include mobile{
							display:inline-block;
							width:25%;
							padding: 0 5px;
							margin-top: 17px;
							text-align:center;
							vertical-align:top;
							&:nth-child(-n + 4) {
								margin-top: 0;
							}
						}
						a {
							display:block;
							overflow:hidden;
							font-size:0;
							@include mobile{
								padding: 0;
							}
							i {
								display:inline-block;
								width:64px;
								height:64px;
								@include mobile {
									width: 40px;
									height: 40px;
								}
								img {
									width:100%;
								}
							}
							.txt {
								display:inline-block;
								position:relative;
								padding:0 18px 0 16px;
								font-size:20px;
								font-weight:500;
								line-height:28px;
								vertical-align:middle;
								@media screen and (max-width:980px) {
									font-size:18px;
									line-height:26px;
								}
								@include mobile{
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									width:100%;
									height: 36px;
									margin-top:6px;
									padding:0 2px;
									font-size:13px;
									line-height:19px;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
									word-break: break-all;
								}
							}
						}
						&:hover {
							.txt {
								// text-decoration:underline;
								&:after {
									content:' ';
									position:absolute;
									top:50%;
									right:0;
									width:16px;
									height:16px;
									margin-top:-8px;
									background:url('/lg5-common/images/icons/btn_arrow_right_16.svg') no-repeat 0 0;
									@include mobile {
										display:none;
									}
								}
							}
						}
					}
				}
			}
		}
		//추천제품
		&.wide {
			max-width:1872px;
			.slide-wrap.carousel-type.carousel-4 {
				max-width:100%;
				padding-bottom:0;
				@include mobile{
					margin:0;
					padding:0;
				}
				.slide-content {
					margin:0 -12px;
					@include mobile{
						margin:0;
						padding:0 12px;
						overflow:hidden !important;
					}
					.slide-track{
						@include mobile{
							&.solo{
								width:100% !important;
								>li{
									width:100% !important;
								}
							}
						}
						.slide-conts{
							@include mobile{
								padding:0 4px;
							}
						}
					}
					.slide-box {
						position:relative;
						border-radius:8px; // BTOCSITE-11585 제품 - 이달의 추천 제품 모델명 노출 요청의 건  
						&:after{
							content:'';
							position:absolute;
							top:0;
							left:0;
							width:100%;
							height:100%;
							opacity: 0.5;
							background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 79%);
						}
						img {
							width:100%;
						}
						.inner {
							position:absolute;
							z-index:1;
							bottom:50px;
							left:40px;
							@media screen and (max-width: 1872px){
								bottom:11%;
								left:9%;
							}
							@include mobile {
								bottom:25px;
								left:20px;
							}
							> span {
								padding:0 12px;
								border:1px solid #fff;
								border-radius:4px;
								font-size:12px;
								font-weight:500;
								line-height:24px;
								color:#fff;
								@include mobile{
									font-size:12px;
									line-height:11px;
								}
							}
							> p {
								margin-top:6px;
								color:#fff;
								font-size:40px;
								font-weight:700;
								line-height:1.2;
								@media screen and (max-width: 1872px){
									font-size:2.1vw;
								}
								@media screen and (max-width: 1023px){
									font-size:1.5vw;
								}
								@include mobile{
									margin-top:7px;
									font-size:24px;
									line-height:30px;
								}
							}
						}
					}
				}
				.slide-controls {
					max-width:100%;
					.prev {
						left:14px;
						@include mobile{
							left:-12px;
						}
					}
					.next {
						right:14px;
						@include mobile{
							right:-12px;
						}
					}
				}
			}	
		}
		
		//이달의 추천 제품
		// BTOCSITE-4349 [UI] 스토어 홈 > 많이 구매하는 제품 (이달의 추천제품) 영역 수정
		&.module-buy-product {
			padding:60px 40px 70px;
			max-width:none;
			background:#f4f4f4;
			@include mobile{
				padding:40px 0 50px;
			}

			.scroll-wrap {
				margin-bottom:14px; 
				text-align: center;
				@include mobile {
					margin-bottom: 3px;
					&:before,
					&:after {
						position: absolute;
						top:-6px;
						z-index: 1;
						width: 40px;
						height: 43px;
						content: '';
					}
					&:before {
						left: 0;
						background-image: linear-gradient(to left, rgba(244, 244, 244, 0), #f4f4f4);
					}
					&:after {
						right: 0;
						background-image: linear-gradient(to right, rgba(244, 244, 244, 0), #f4f4f4);
					}
				}
				&.border-type {
					margin-top:0;
					.tabs {
						li {
							margin-left:8px;
							padding:0;
							a {
								position:relative;
								padding:0 24px;
								height:38px;
								font-size:18px;
								font-weight:700;
								line-height:36px;
								border:1px solid transparent;
								@include mobile{
									padding:0 15px; // BTOCSITE-11585 제품 - 이달의 추천 제품 모델명 노출 요청의 건  
									height:32px;
									font-size:14px;
									line-height:30px;
								}
							}
							&.on {
								a{
									border:1px solid #000;
									border-radius:19px;
								}
							}
							&:first-child{
								margin-left:0;
							}
						}
					}
					&:before {
						@include mobile{
							display:block;
						}
					}
				}
			}

			.buy-product-tabcontent{
				margin:0 auto;
				max-width:1400px;
				.prd-list-wrap{
					padding:0 -10px;
					.slide-wrap{
						@include mobile{
							padding-bottom:0;
						}
					}
				}
			}

			.tooltip-wrap {
				margin-top: -5px;
				vertical-align: middle;
			}

			.tooltip-box {
				.title { font-size:14px; color:#000; }
				.bullet-list {
					.b-txt {
						padding-left:0;
						&::before{
							display:none;
						}
					}
				}
				.btn-close {
					top:16px;
				}
			}

			.tab-content {
				margin-top:14px;
				font-size:0;
				@include mobile{
					margin-top:3px;
					padding:0 6px;
				}
				&:after {
					content:' ';
					display:block;
					clear:both;
				}
				.inner{
					width:100% !important;
				}
				.item {
					display:flex;
					padding:10px;
					width:100% !important;
					@include mobile{
						display:block;
						width:100% !important;
					}
				}
				.best {
					width:50%;
					padding-right:12px;
					@include mobile{
						width:100%;
						height:125px; // BTOCSITE-11585 제품 - 이달의 추천 제품 모델명 노출 요청의 건  
						padding:0;
					}
					a {
						display:block;
						position:relative;
						width:100%;
						height:100%;
						padding:60px 0;
						border-radius:8px;
						background:#fff;
						overflow:hidden;
						box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.14);
						// padding:20px 104px 40px;
						@include mobile {
							display:flex;
							padding:16px 20px 16px 20px; // BTOCSITE-11585 제품 - 이달의 추천 제품 모델명 노출 요청의 건  
						}

						&.no-border { box-shadow:none; }
						.flag {
							position:absolute;
							top:0;
							left:0;
							.num{
								display:block;
								width:104px;
								height:110px;
								background:url('/lg5-common/images/PRS/img-flag-buy-best.svg') no-repeat 0 0;
								@include mobile{
									width:25px;
									height:26.5px;
									background:url('/lg5-common/images/PRS/img-flag-buy-best-m.svg') no-repeat 0 0;
									background-size:100% 100%;
								}
							}
							@include mobile{
								width:53px;
								img {
									width:100%;
								}
							}
						}
						.img {
							text-align:center;
							@include mobile{
								position:relative;
								top:50%;
								width:80px;
								height:80px;
								transform:translateY(-50%);
							}
							img {
								width:100%;
								height:100%;
								max-width:473px;
								max-height:473px;
								@include mobile{
									width: 100%;
									height:100%;
								}
								&.no-img {
									height:0;
									padding-bottom: 69.33%;
									@include mobile{
										padding-bottom: 45.74%;
									}
								}
							}
						}
						.product-info {
							position:absolute;
							bottom:70px;
							right: 48px;
							left:48px;
							text-align: center;
							@media screen and (max-width:980px) {
								margin-right:5.9%;
								width:calc(100% - 120px - 5.9%);
							}
							@include mobile{
								position:relative;
								bottom:auto;
								right: 0;
								left:0;
								text-align: left;
								margin-left:20px;
								width:calc(100% - 100px);
							}
							.tit {
								font-weight:700;
								font-size:40px;
								line-height:48px;
								display: -webkit-box;
								-webkit-line-clamp:2;
								-webkit-box-orient: vertical;
								max-height:100px;
								overflow: hidden;
								text-overflow: ellipsis;
								@include mobile{
									max-height:70px;
									font-size:14px;
									line-height:20px;
								}
							}
							/* BTOCSITE-11585 제품 - 이달의 추천 제품 모델명 노출 요청의 건 */
							.code{
								display:block;
								margin-top:8px;
								font-size:20px;
								line-height:28px;
								color:#666;
								@include mobile{
									margin-top:4px;
									font-size:13px;
									line-height:19px;
								}
							}
							.price-area {
								display: flex;
								flex-wrap: wrap;
								flex-direction: row-reverse;
								justify-content:center;
								align-items: baseline;			
								margin-top:20px;
								@include mobile{
									position:absolute;
									bottom:0;
									font-size: 0;
									justify-content:flex-end;
								}
								.original {
									position: relative;
									display: inline-block;
									margin-left:12px;
									@include mobile{
										display:none;
									}
									.price {
										font-size:20px;
										line-height:28px;
										color: #666;
										text-decoration:line-through;
										font-weight:500;
										@include mobile{
											font-size:16px;
											line-height:24px;
										}
									}
								}
								.total{
									.price {
										font-weight:700;
										font-size:28px;
										line-height:34px;
										@include mobile{
											font-size:16px;
											line-height:24px;
										}
									}
								}
							}
							/* // BTOCSITE-11585 제품 - 이달의 추천 제품 모델명 노출 요청의 건   */
						}
					}
				}
				.list {
					padding-left:12px;
					width:50%;
					&:after {
						content:' ';
						display:block;
						clear:both;
					}
					@include mobile{
						padding-left:0;
						width:100%;
					}
					li {
						margin-top:24px;
						width:100%;
						&:first-child{
							margin-top:0;
						}
						@include mobile{
							margin-top:8px;
							height:125px; // BTOCSITE-11585 제품 - 이달의 추천 제품 모델명 노출 요청의 건
							&:first-child{
								margin-top:8px;
							}
						}
						a {
							display:flex;
							position:relative;
							height:100%;
							padding:28px 40px;
							border-radius:8px;
							background:#fff;
							box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.14);
							@include mobile {
								padding:16px 20px 16px 20px; // BTOCSITE-11585 제품 - 이달의 추천 제품 모델명 노출 요청의 건
								box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.14);
							}
							&.no-border { box-shadow:none; }
							.flag {
								position:absolute;
								top:12px;
								left:24px;
								@media screen and (max-width:980px) {
									top:8px;
									left:14px;
								}
								@include mobile{
									top:6px;
									left:12px;
								}
								.num {
									color:#ec455a;
									font-size:24px;
									font-weight:700;
									line-height:34px;
									@include mobile{
										font-size:16px;
										line-height:24px;
									}
								}
							}
							.img {
								width:120px;
								height:120px;
								text-align:center;
								@include mobile{
									position:relative;
									top:50%;
									width:80px;
									height:80px;
									transform:translateY(-50%);
								}
								img {
									width:100%;
									height:100%;
									&.no-img {
										height:0;
										padding-bottom: 72%;
										@include mobile{
											padding-bottom: 74.63%;
										}
									}
								}
							}
							.product-info {
								position:relative;
								margin-left:40px;
								width:calc(100% - 160px);
								@media screen and (max-width:980px) {
									margin-left:5.9%;
								width:calc(100% - 120px - 5.9%);
								}
								@include mobile{
									margin-left:20px;
									width:calc(100% - 100px);
								}
								.tit {
									font-size:18px;
									font-weight:700;
									line-height:26px;
									overflow: hidden;
									white-space:nowrap;
									text-overflow: ellipsis;
									@include mobile{
										display: -webkit-box;
										-webkit-line-clamp:2;
										-webkit-box-orient: vertical;
										max-height:70px;
										white-space:normal;
										font-size:14px;
										line-height:20px;
									}
								}
								.code{
									display:block;
									margin-top:6px;
									font-size:16px;
									line-height:24px;
									color:#666666;
									@include mobile{
										margin-top:4px; // BTOCSITE-11585 제품 - 이달의 추천 제품 모델명 노출 요청의 건  
										font-size:13px;
										line-height:19px;
									}
								}
								.price-area {
									display: flex;
									flex-wrap: wrap;
									flex-direction: row-reverse;
									justify-content: flex-end;
									align-items: baseline;
									position:absolute;
									bottom:0;
									font-size: 0;				
									.total {
										margin-right: 8px;
										font-size:20px;
										font-weight:700;
										line-height:28px;
										@include mobile{
											font-size:16px;
											line-height:24px;
										}
									}
									.original {
										position: relative;
										display: inline-block;
										margin-right: 4px;
										color: #666;
										font-size:16px;
										line-height:24px;
										font-weight:500; // BTOCSITE-11585 제품 - 이달의 추천 제품 모델명 노출 요청의 건  
										text-decoration:line-through;
										@include mobile{
											display:none;
											line-height:20px;
											font-size:14px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		//기획전
		.plan-exhib-slide {
			padding-bottom:32px;
			@include mobile{
				padding-bottom:24px;
			}
			&.slide-solo {
				padding-bottom:0;
			}
			.inner {
				.img {
					border-radius:8px;
					overflow:hidden;
					@include mobile{
						height:600px;
					}
					img {
						min-height:496px;
						@include mobile{
							width:100%;
						}
					}
				}
				.product-info {
					position:absolute;
					top:48px;
					left:32px;
					color:#000;
					@include mobile{
				
					}
					&.fc-black {
						.tit,
						.date {
							color:#000;
						}
						.btn.border {
							border-color:#000 !important;
							color:#000;
						}
					}
					&.fc-white {
						.tit,
						.date {
							color:#fff;
						}
						.btn.border {
							border-color:#fff !important;
							color:#fff;
						}
					}
					.tit {
						font-size:48px;
						line-height:64px;
						font-weight:700;
						@include mobile{
							font-size:28px;
							line-height:38px;
							word-break:keep-all;
						}
						sup {
							display:block;
							margin-bottom:4px;
							font-size:18px;
							font-weight:500;
							line-height:28px;
							@include mobile{
								font-size:14px;
								line-height:22px;
							}
						}
					}
					.date {
						margin-top:8px;
						font-size:18px;
						font-weight:700;
						line-height:28px;
						opacity:.6;
						@include mobile{
							margin-top:12px;
							font-size:14px;
							line-height:22px;
						}
					}
					.btn {
						margin-top:48px;
						@include mobile{
							margin-top:24px;
						}
						&.border {
							&:hover,
							&:focus,
							&:active {
								border-color:$color-primary !important;
								color:#fff;
							}
						}
					}
				}
				.product-list {
					position:absolute;
					top:50%;
					right:32px;
					transform:translateY(-50%);
					max-width:638px;
					@include mobile{
						top:auto;
						right:16px;
						bottom:20px;
						left:16px;
						max-width:100%;
						transform:translateY(0);
					}
					ul {
						li {
							padding:12px 0;
							@include mobile{
								padding:4px 0;
							}
							a {
								display:block;
								position:relative;
								height:220px;
								padding:16px 48px 16px 24px;
								border-radius:8px;
								background-color:#fff;
								box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.14);
								@include mobile {
									height:130px;
									padding:18px 12px;
									box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.14);
								}
								.img {
									position:absolute;
									top:50%;
									left:24px;
									width:188px;
									height:188px;
									margin-top:-94px;
									@include mobile{
										width:94px;
										height:94px;
										margin-top:-47px;
									}
									img {
										width:100%;
										min-height:100%;
									}
								}
								.info {
									padding:12px 0 0 212px;
									@include mobile{
										padding:0 0 0 114px;
									}
									.model {
										display: -webkit-box;
										-webkit-line-clamp:2;
										-webkit-box-orient: vertical;
										max-height:56px;
										font-size:18px;
										font-weight:700;
										line-height:28px;
										overflow: hidden;
                    					text-overflow: ellipsis;
										@include mobile{
											max-height:44px;
											font-size:14px;
											line-height:22px;
										}
									}
									.code {
										font-size:16px;
										font-weight:500;
										line-height:26px;
										@include mobile{
											font-size:13px;
											line-height:21px;
										}
									}
									.price-area {
										display: flex;
										flex-wrap: wrap;
										flex-direction: row-reverse;
										justify-content: flex-end;
										align-items: baseline;
										position:absolute;
										bottom:48px;
										font-size: 0;
										@include mobile{
											bottom:16px;
										}							
										.total {
											margin-right: 8px;
											font-weight: 700;
											font-size: 20px;
											line-height: 32px;
											@include mobile{
												font-size:13px;
												line-height:21px;
											}								
										}
										.original {
											position: relative;
											display: inline-block;
											margin-right: 4px;
											font-size: 16px;
											line-height: 32px;
											color: #767676;
											text-decoration:line-through;
											@include mobile{
												font-size:16px;
												line-height:24px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.slide-controls {
				max-width:100%;
				@include mobile{
					margin-top:-40px;
				}
				.prev {
					left:-23px;
					@include mobile{
						left:4px;
					}
				}
				.next {
					right:-23px;
					@include mobile{
						right:4px;
					}
				}
			}
		}
		//카테고리 추천 배너
		.slide-wrap.product-slide {
			padding-bottom:26px;
			@include mobile{
				padding-bottom:0;
			}
			.indi-wrap{
				@include mobile{
					display:none;
				}
			}
			.slide-content {
				@include mobile{
					padding:0 12px;
				}
				.slide-track {
					@include mobile{
						&.solo{
							width:100% !important;
							>li{
								width:100% !important;
							}
						}
					}
					.slide-conts {
						@include mobile {
							margin-top:0;
							padding:0 4px;
						}
					}
				}
			}
			.slide-controls{
				top:calc(50% - 13px);
				@include mobile{
					display:none;
				}
				.btn-arrow{
					padding:0;
					width:40px;
					height:40px;
					&.prev{
						left:-20px;
					}
					&.next{
						right:-20px;
					}
					&.disabled{
						display: inline-block !important;
						opacity: 0.7;
						cursor: default;
					}
				}
			}
			.product-banner {
				position:relative;
				max-width:1848px;
				margin:0 auto;
				& + .product-banner {
					margin-top:24px;
				}
				& a{
					display:inline-block;
				}
				& img {
					max-width:1848px;
					width:100%;
					border-radius:8px; // BTOCSITE-11585 제품 - 이달의 추천 제품 모델명 노출 요청의 건  
				}
				> img {
					@include mobile{
						width:100%;
					}
				}
				.product-info-link {
					display:block;
					position:absolute;
					top:50%;
					color:#000;
					text-align:center;
					transform:translateY(-50%);
					@include pc{
						min-width:520px;
					}
					@include mobile{
						top:40px;
						right:0;
						left:0;
						text-align:center;
						transform:translateY(0);
					}
					&.type-l {
						left:6.15%;
						@include mobile{
							left:0;
						}
					}
					&.type-r {
						right:6.15%;
						@include mobile{
							right:0;
						}
					}
					> a {
						display:block;
					}
					.sub-tit{
						font-size:24px;
						font-weight:700;
						line-height:32px;
						@include mobile{
							font-size:16px;
							line-height:26px;
						}
					}
					.tit {
						font-size:40px;
						line-height:48px;
						font-weight:700;
						@include mobile{
							padding:0 24px;
							font-size:27px;
							line-height:34px;
							word-break:keep-all;
						}
					}
					.btn {
						margin-top:16px;
						// font-size:0.85vw;
					}
					&.fc-white{
						color:#fff;
						.btn.border {
							border:1px solid #fff !important;
							color:#fff;
						}
					}
				}
				.link-area {
					margin-top:32px;
					font-size:0;
					@include mobile{
						margin-top:24px;
					}
					.txt-link {
						margin:0 16px;
						font-size:16px;
						font-weight:500;
						line-height:26px;
						text-decoration:underline;
						@include mobile{
							display:block;
							font-size:14px;
							line-height:22px;
						}
						> span {
							position:relative;
							padding-right:20px;
							@include mobile{
								padding-right:22px;
							}
							&:after {
								content:' ';
								position:absolute;
								top:6px;
								right:0;
								width:16px;
								height:16px;
								background:url('/lg5-common/images/icons/btn-arr-16-black.svg') no-repeat 0 0;
								@include mobile{
									top:0;
									width:20px;
									height:20px;
									background-size:20px;
								}
							}
						}
						& + .txt-link {
							@include mobile{
								margin-top:12px;
							}
						}
					}
				}
				
			}
		}
		
		
		//신제품
		.new-product-list {
			padding-bottom:0;
			@include mobile{
				padding:0 12px 0;
			}
			.slide-content {
				margin:-12px;
				@include mobile{
					margin:0 -12px;
					padding: 0 12px;
				}
			}
			.slide-track {
				display:flex;
				flex-wrap:wrap;
				font-size:0;
				@include mobile{
					margin:0;
				}
				.slide-conts {
					display:inline-block;
					position:relative;
					width:25%;
					padding:12px;
					vertical-align:top;
					overflow:hidden;
					@include mobile{
						width:auto;
						padding:4px;
					}
					.slide-box {
						display:block;
						height:100%;
						padding:40px;
						border-radius:8px;
						box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.14);
						@media screen and (max-width:980px) {
							padding:12%;
						}
						@include mobile {
							padding:20px 16px;
							box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.14);
						}
					}
					.img {
						display:block;
						text-align:center;
						img {
							max-width:200px;
							width:100%;
							@include mobile{
								max-width:103px;
							}
						}
					}
					.product-contents {
						position:relative;
						// min-height:114px;
						padding-top:61px;
						@include mobile{
							padding-top:20px;
							min-height:112px;
						}
						.flag-wrap {
							position:absolute;
							top:40px;
							left:0;
							@include mobile{
								display:none;
							}
							.flag {
								&:before {
									background-color:#dddddd;
								}
							}
						}
						.tit {
							display: -webkit-box;
							-webkit-line-clamp:1;
							-webkit-box-orient: vertical;
							font-size:18px;
							font-weight:700;
							line-height:26px;
							overflow: hidden;
							text-overflow: ellipsis;
							@include mobile{
								-webkit-line-clamp:2;
								max-height:40px;
								white-space:normal;
								font-size:14px;
								line-height:20px;
							}
							> a {
								display:block;
							}
						}
						.product-sku {
							display: inline-block;
							margin-right:12px;
							font-size:14px;
							line-height:20px;
							vertical-align: top;
							color:#666;
							@include mobile{
								margin-top:3px;
							}
						}
						.review-info {
							display: inline-block;
							font-size: 0;
							vertical-align: top;
							@include mobile{
								display:none;
							}
							> a {
								display:block;
							}
							.star,
							.average-rating,
							.review-count {
								display: inline-block;
								color:#666;
								font-size:14px;
								line-height:20px;
							}
							.review-count {
								padding-left:4px;
							}
							.star {
								width: 20px;
								height: 20px;
								margin-right: 2px;
								background: url('/lg5-common/images/icons/icon-star-off-20.svg') no-repeat 0 0;
								vertical-align: -5px;
								&.is-review {
									background-image: url('/lg5-common/images/icons/icon-star-on-20.svg');
								}
							}
						}
					}
					.product-price {
						@include clearfix;
						position:relative;
						z-index:1;
						margin-top: 8px;
						white-space: nowrap;
						font-size: 0;
						> a {
							display:block;
						}
						.total {
							font-weight: 700;
							font-size:20px;
							line-height:28px;
							@include mobile{
								font-size: 16px;
								line-height: 24px;
							}
						}
						.original {
							position: relative;
							margin-top:20px;
							min-height:24px;
							font-size:16px;
							line-height:24px;
							color:#666666;
							text-decoration:line-through;
							@include mobile{
								display:none;
							}
						}
					}
					&.img-type {
						.slide-box {
							padding:0;
							overflow:hidden;
							@include mobile{
								padding-top:0;
							}
						}
						.product-area {
							position:absolute;
							right:calc(12% + 12px);
							bottom:52px;
							left:calc(12% + 12px);
							@include mobile {
								right:0;
								bottom:29px;
								left:0;
								padding:0 16px;
							}
						}
						.img {
							position:relative;
							height:100%;
							&:after{
								content:'';
								position:absolute;
								top:0;
								right:0;
								bottom:0;
								left:0;
								background:rgba(0, 0, 0, 0.2);
							}
							@include mobile{
								padding:6px;
							}
							img {
								height:100%;
								position:absolute;
								top:0;
								bottom: 0;
								left:50%;
								transform:translateX(-50%);
								max-width:none;
								width:auto;
								@include mobile{
									height:auto;
									top:50%;
									transform:translate(-50%, -50%) scale(0.5);
									@media screen and (max-width: 480px){
										transform:translate(-50%, -50%) scale(0.4);
									}
								}
							}
						}
						.product-contents {
							.tit,
							.product-sku {
								color:#fff;
							}	
						}
						.product-price .original,
						.product-price .total {
							color:#fff;	
						}
						.review-info{
							color:#fff;
							.average-rating,
							.review-count{
								color:#fff;
							}	
						}
					}
				}
			}
			.slide-controls {
				display:none;
			}
		}
		//제품 추천
		.product-recom-slide {
			margin:-12px;
			padding-bottom:16px;
			@include mobile{
				margin:0 0 -6px;
				padding-bottom:0;
			}
			.slide-content {
				.slide-conts {
					padding:12px;
					@include mobile{
						padding:6px 16px;
					}
					.slide-box {
						display:block;
						position:relative;
						height:224px;
						padding:32px 40px;
						border-radius:8px;
						background-color:#fff;
						box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.14);
						@media screen and (max-width:980px) {
							padding:32px 5.9%;
						}
						@include mobile {
							height:auto;
							min-height:135px;
							padding:16px 20px;
							box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.14);
						}
						.img {
							position:absolute;
							top:50%;
							left:40px;
							width:160px;
							height:160px;
							transform:translateY(-50%);
							@media screen and (max-width:980px) {
								width:120px;
								height:120px;
							}
							@include mobile{
								left:20px;
								width:90px;
								height:90px;
							}
							img {
								width:100%;
							}
						}
						.info {
							padding:12px 0 0 200px;
							@media screen and (max-width:980px) {
								padding:12px 0 0 160px;
							}
							@include mobile{
								padding:0 0 0 110px;
							}
							.model {
								display: -webkit-box;
								-webkit-line-clamp:2;
								-webkit-box-orient: vertical;
								max-height:56px;
								font-size:18px;
								font-weight:700;
								line-height:26px;
								overflow: hidden;
								text-overflow: ellipsis;
								@include mobile{
									max-height:40px;
									font-size:14px;
									line-height:20px;
								}
							}
							.code {
								font-size:16px;
								line-height:24px;
								color:#666;
								@include mobile{
									margin-top:5px; // BTOCSITE-11585 제품 - 이달의 추천 제품 모델명 노출 요청의 건  
									font-size:13px;
									line-height:19px;
								}
							}
							.price-area {
								display: flex;
								flex-wrap: wrap;
								flex-direction: row-reverse;
								justify-content: flex-end;
								align-items: baseline;
								position:absolute;
								bottom:40px;
								font-size: 0;
								@include mobile{
									bottom:16px;
								}
								.total {
									margin-right: 8px;
									font-weight: 700;
									font-size: 20px;
									line-height:28px;
									@include mobile{
										font-size:16px;
										line-height:24px;
									}
								}
								.original {
									position: relative;
									display: inline-block;
									margin-right: 4px;
									font-size: 16px;
									line-height:24px;
									color: #767676;
									text-decoration:line-through;
									@include mobile{
										font-size:13px;
										line-height:19px;
									}
								}
							}
						}
					}
				}
			}
			.slide-controls {
				max-width:1460px;
				margin-top:-8px;
				@include mobile{
					display:none;
				}
				.prev {
					left:-12px;
				}
				.next {
					right:-12px;
				}
			}
		}
	}
	/* //BTOCSITE-6882 신규 WSG 적용 - 스토어 */

	//2021-08-17 BTOCSITE-4270 [UI] 스토어 홈 내에 "엘라쇼 홈"게이트웨이 추가
	.content-banner {
		max-width:1460px;
		margin:100px auto 0;
		padding:0 40px;

		@include mobile {
			margin:58px auto 0;
			padding:0 16px;
		}

		.banner {
			&-inner {
				> a {
					display: block;
					position: relative;
					overflow: hidden;
					border-radius:8px;
				}
			}
			//BTOCSITE-7335 &-img 추가
			&-img {
				position: relative;
				height:200px;

				img {
					position:absolute;
					top:0;
					left:50%;
					height:100%;
					transform:translateX(-50%);
				}

				@include mobile {
					height:auto;

					img {
						position: static;
						top:0;
						left:0;
						transform:none;
						width:100%;
					}
				}
			}
			&-bg {
				position: relative;
				background-repeat: no-repeat;

				&.only-pc {
					display: block !important;
					height:200px;
					background-size: auto 100%;
					background-position: center center;
				}
				&.only-mo {
					display: none !important;
					height:66.6666vw;
					// height:240px;
					background-size: 100% 100%;
					background-position: bottom center;
				}

				img {
					width:100%;
				}

				@include mobile {
					&.only-pc {
						display: none !important;
					}
					&.only-mo {
						display: block !important;
					}	
				}
			}
			&-content {
				display: flex;
				flex-direction: column;
				position: absolute;
				top:0; 
				left:0;
				width:50%;
				height:100%;
				align-items:center;
				justify-content: center;

				@include mobile {
					width:100%;
					justify-content: flex-start;
					padding:7.7777vw 20px 0;
				}
			}
			&-desc {
				font-size:18px;
				font-weight:500;
				color:#000;
				line-height:1.56;

				@include mobile {
					font-size:14px;
				}
			}
			&-title {
				display: block;
				position: relative;
				font-size:40px;
				line-height:1.4;
				font-weight:bold;
				color:#000;

				> span {
					display: inline-block;
					vertical-align: middle;
				}

				&:after {
					content:"";
					display: inline-block;
					width:40px;
					height:40px;
					margin-left:4px;
					vertical-align: middle;
					background: url("/lg5-common/images/PRS/img_shine_40.svg") no-repeat center center;
					background-size:100% 100%;
				}

				@include mobile {
					margin-top:7px;
					font-size:27px;

					&:after {
						width:32px;
						height:32px;
					}
				}
			}
		}
	}
}